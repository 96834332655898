import React, {Component} from 'react';
import "./Login.css";
import Util from '@/util/Util';
import {title} from "@/constant/index";
import Validate from '@/util/Validate';
import xhr from '@/service/xhr/index';
import * as Action from "@/store/token-action";
import { connect } from "react-redux";
import {setUserInfo} from "../../store/token-action";

var t;
class Login extends Component{
    constructor(props) {
        super(props);
        this.state={
            scrollTo: 0,
            countDown: 60,
            getSmsCodeText: "获取验证码",
            getSmsCodeDisabled: true,
            param:{
               mobile: "",
               smsCodeType: "15",
               smsCode:""
            }
        }
    }
    componentWillMount() {
        const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        this.setState(state => ({
            scrollTo: scrollTop
        }));
    }

    closeLogin(){
        this.props.setShowLogin(false);
    }

    inputValidate(name, value) {
        if (name === "mobile") {
            return Validate.validateMobile(value, true,
                "请填写手机号","手机号错误");
        }
        if(name==="smsCode"){
            return Validate.validateEmpty(value, true, "请填写短信验证码");
        }
        return true;
    }

    inputOnBlur(event) {
        const name = event.target.name;
        let value = event.target.value;
        const id = event.target.id;
        document.getElementById(id).scrollIntoView();
        this.inputValidate(name, value);
        this.inputChange(event);
    }

    inputChange(event) {
        const name = event.target.name;
        let value = event.target.value;
        if(name==="mobile"){
            value = value.toUpperCase();
        }
        let param = this.state.param;
        param[name] = value;
        this.setState(state => ({
            param: param
        }));
    }

    setTime(e) {

        let that = this;
        if (this.state.countDown === 0) {
            this.setState(state=>({
                getSmsCodeText: "获取验证码",
                countDown: 60,
                getSmsCodeDisabled: false
            }));
            return false;
        } else {
            this.setState(state => ({
                getSmsCodeText: "重新发送" + this.state.countDown,
                countDown: this.state.countDown - 1,
                getSmsCodeDisabled: true
            }));
        }
        clearTimeout(t);
        t =setTimeout(function () {
            that.setTime();
        }, 1000);
    }

    componentWillUnmount() {
        clearTimeout(t);
    }

    getSmsCode(){
        window.scrollTo(0, this.state.scrollTo);
        const param = this.state.param;
        if (!this.inputValidate("mobile", param.mobile)) {
            return;
        }
        const that = this;
        xhr.post('/common/NotNeedImageCode/sendSmsCode',param,true).then(function (data) {
            if(data.code==="1"){
                that.setTime();
            }else{
                Util.showToast(data.message);
                if (data.data) {
                    that.setState(state => ({
                        countDown: data.data
                    }));
                    that.setTime();
                }
            }
        });
    }

    login(){
        const param = this.state.param;
        if (!this.inputValidate("mobile", param.mobile)||!this.inputValidate("smsCode",param.smsCode)) {
            return;
        }
        const that = this;
        xhr.post('/openapi/robot/getUserCode',param,true).then(function (data) {
            if(data.code==="1"){
                that.props.setUserInfo({value: data.data});
                that.props.loginSuccess();
            }else{
                Util.showToast(data.message);
            }
        });
    }

    render(){
        let showLoginDiv = "";
        if (this.props.showLogin) {
            showLoginDiv = (
                <div className={"all-login-box"}>
                    <div className={"zheBg"} onClick={() => this.closeLogin()}/>
                    <div className={"all-login-content"}>
                        <div className={"all-login-title"} id={"login"}>登录</div>
                        <div className={"all-login-input-box"}>
                            <div className={"all-login-input"}>
                                <img src={require("../../assets/images/login/tel_no.png")}
                                     className={"all-login-tel-no"} alt={title}/>
                                <div className={"all-login-input-line"}>
                                    <input type={"tel"} placeholder={"输入手机号"}
                                           value={this.state.mobile}
                                           maxLength={11}
                                           id={"mobile"}
                                           name={"mobile"}
                                           onBlur={() => this.inputOnBlur(event)}
                                           onChange={() => this.inputChange(event)}
                                           pattern="[0-9]*"
                                           className={"all-login-tel-input"}/>
                                </div>
                            </div>
                            <div className={"all-login-input"}>
                                <img src={require("../../assets/images/login/password.png")}
                                     className={"all-login-tel-no"} alt={title}/>
                                <div className={"all-login-input-line"}>
                                    <input type={"tel"} placeholder={"输入验证码"}
                                           maxLength={6}
                                           id={"smsCode"}
                                           name={"smsCode"}
                                           onBlur={() => this.inputOnBlur(event)}
                                           onChange={() => this.inputChange(event)}
                                           pattern="[0-9]*"
                                           className={"all-login-password-input"}/>
                                    <span className={"all-login-sms-button"}
                                          onClick={()=>this.getSmsCode()}>{this.state.getSmsCodeText}</span>
                                </div>
                            </div>
                        </div>
                        <div className={"all-login-button-box"}>
                             <div className={"all-login-button"} onClick={()=>this.login()}>立即登录</div>
                        </div>

                        <div className={"all-login-close-box"}>
                            <img src={require("../../assets/images/login/close.png")}
                                 onClick={() => this.closeLogin()}
                                 className={"all-login-close"} alt={title}/>
                        </div>
                    </div>
                </div>
            );
        }
        return(
            <div>
                {showLoginDiv}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state
});

export default connect(mapStateToProps,Action)(Login);
