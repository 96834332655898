import React, { Component } from 'react';
import {
    BrowserRouter,
    Route,
    Link,
    Switch,
    withRouter,
    Redirect,
    hashHistory,
    HashRouter
} from 'react-router-dom';

import 'antd-mobile/dist/antd-mobile.css';
import '@/assets/css/common.css';
import routers from '@/routers/index';
import * as Action from "@/store/token-action";
import { connect } from "react-redux";
import {title} from '@/constant/index';
require('promise.prototype.finally').shim();

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false
        }
    }
    render() {

        return (
            <div className="App">
                <BrowserRouter history={hashHistory}>
                    {
                        routers.map((route,index) => {
                            return(
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    component={route.component}/>
                            )
                        })
                    }
                </BrowserRouter>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state
});

export default connect(mapStateToProps,Action)(App);
