import React,{ Component } from 'react';
import  './ProductDetail.less';
import {rootPath} from "@/service/xhr/config";
import Piwik from '@/util/Piwik';
import {title} from "@/constant/index";
import queryString from 'query-string';
import Util from '@/util/Util';
import {Checkbox, Picker,Popover,Icon,Modal} from 'antd-mobile';
const operation = Modal.operation;
const Item = Popover.Item;
const CheckboxItem = Checkbox.CheckboxItem;
import Share from '@/util/Share';
import * as Action from "@/store/token-action";
import { connect } from "react-redux";

import image50_1 from '../../../assets/images/shaoerbaixiebing/plan_50_1.png';
import image50_2 from '../../../assets/images/shaoerbaixiebing/plan_50_2.png';

import image30_1 from '../../../assets/images/shaoerbaixiebing/plan_30_1.png';
import image30_2 from '../../../assets/images/shaoerbaixiebing/plan_30_2.png';

import imageLi1 from '../../../assets/images/shaoerbaixiebing/li_1.png';
import imageLi2 from '../../../assets/images/shaoerbaixiebing/li_2.png';
import imageLi3 from '../../../assets/images/shaoerbaixiebing/li_3.png';
import {List} from "antd-mobile/lib/list";
import copy from 'copy-to-clipboard';


class ProductDetail extends Component{
    constructor(props) {
        super(props);
        this.state={
            agree:false,
            phone:"4000-518-666",
            tel:"13102061402",
            wx:"fairyzoo",
            visible:false,
            onlineService:false,
            activeService:1,
            productPlan:{
                amount30: 30,
                amount50: 50
            },
            activeAmount:50,
            channelNo:"",
        }
    }

    componentWillMount() {
        let urlParams = queryString.parse(this.props.location.search);
        let channelNo = "";
        if (urlParams!==undefined) {
            channelNo = urlParams.channelNo;
            this.setState(state => ({
                channelNo: channelNo
            }));
            if(channelNo && channelNo !=="undefined"){
                this.props.setChannelNo({value:channelNo});
            }
        }

        Piwik.pageView();
        Share.initSrbxbWxShare(channelNo);
    }

    scrollToAnchor (){
        this.closeCallBox();
        this.setState(state=>({
            activeService:2
        }));
        document.getElementById("srbxb-service-box").scrollIntoView();
        //点击健康告知埋点
        Piwik.push('凯叔','健康告知','重大疾病',1);
    }

    agreeChange() {
        const that = this;
        this.closeCallBox();
        this.setState(state=>({
            agree: !that.state.agree
        }));
    }

    switchService(num){
        this.setState(state=>({
            activeService:num
        }));
        //点击保险保障服务埋点
        if (num === 2) {
            Piwik.push('凯叔','活动说明页','保险保障服务',1);
        }

    }
    switchAmount(amount){
        this.setState(state=>({
            activeAmount:amount
        }));
        const v3 = amount + "万方案";
        Piwik.push('凯叔', '选择方案', v3, 1);
    }

    toProblem(){
        this.closeCallBox();
        Piwik.push('凯叔','选择方案','常见问题',1);
        const channelNo = this.state.channelNo;
        this.props.history.replace({pathname:'/srbxQuestion',state:{channelNo:channelNo}});
    }

    toCustomer(){
        Piwik.push('凯叔','选择方案','联系客服',1);
    }

    openCallBox(){
        const that = this;
        this.setState(state => ({
            visible: !that.state.visible
        }),function () {
            that.toCustomer();
        });
    }

    closeCallBox(){
        this.setState(state => ({
            visible: false
        }));
    }

    toDataInput(){
        this.closeCallBox();
        Util.showToast("尊敬的用户本次活动已经结束，后续我们会持续为已投保用户提供服务和保障。如有疑问可联系客服");
        // return;
        // if (!this.state.agree) {
        //     Util.showToast("请确认子女不存在《健康告知》中的情形");
        //     return;
        // }
        // const amount = this.state.activeAmount;
        // const channelNo = this.state.channelNo;
        // this.props.history.replace({pathname:'/srbxbInput',state:{amount:amount,channelNo:channelNo}});
    }

    copayValue(value){
        copy(this.state.wx);
        Util.showToast("已复制成功");
    }

    openOnlineService(){
        const that = this;
        this.setState(state => ({
            onlineService: !that.state.onlineService
        }),function () {
            that.toCustomer();
        });
    }

    closeOnlineService(){
        const that = this;
        this.setState(state => ({
            onlineService: !that.state.onlineService
        }));
    }

    onWrapTouchStart(e){
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }

    render(){


        let plan30Style = {
            backgroundRepeat: "no-repeat",
            backgroundSize: "112px 60px",
            height: "60px",
            width: "50%",
            display: "inline-block",
            backgroundImage: `url(${image30_1})`
        };

        let plan50Style = {
            backgroundPositionX: "80%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "112px 60px",
            height: "60px",
            width: "50%",
            display: "inline-block",
            backgroundImage: `url(${image50_2})`
        };
        let tip = "根据孩子性别年龄不同，预计总费用：400-800元";
        if (this.state.activeAmount === 30) {
            plan30Style["backgroundImage"] = `url(${image30_2})`;
            plan50Style["backgroundImage"] = `url(${image50_1})`;
        }

        if (this.state.activeAmount === 50) {
            plan30Style["backgroundImage"] = `url(${image30_1})`;
            plan50Style["backgroundImage"] = `url(${image50_2})`;
            tip = "根据孩子性别年龄不同，预计总费用：600-1200元";
        }

        let serviceStyleLi1 = "srbxb-service-li";
        let serviceStyleLi2 = "srbxb-service-li";
        let serviceStyleLi3 = "srbxb-service-li";
        let serviceContent = "";
        if (this.state.activeService === 1) {
            serviceStyleLi1 = "active-li-1";
            serviceContent = (
                <div className={"srbxb-yiliao-box"}>
                    <div className={"srbxb-service-line"}>
                        <label>·就医引导：</label>
                        <div className={"srbxb-service-text"}>为确诊的患者提供一站式就医引导服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·慰问关怀：</label>
                        <div className={"srbxb-service-text"}>为患者及家庭提供身心康复指导和心灵疏导</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·营养搭配：</label>
                        <div className={"srbxb-service-text"}>根据病人状况，提供营养搭配的指引服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·康复跟踪：</label>
                        <div className={"srbxb-service-text"}>治疗结束前后的2年内提供康复、健康医疗咨询服务</div>
                    </div>
                </div>
            );
        }
        if (this.state.activeService === 2) {
            serviceStyleLi2 = "active-li-2";
            serviceContent = (
                <div className={"srbxb-yiliao-box"}>
                    <div className={"srbxb-service-line"}>
                        <label>·保险服务：</label>
                        <div className={"srbxb-service-text"}>确诊后一次性提供30万或50万赔付	</div>
                        <div className={"srbxb-service-text-sub"}>
                            <div className={"srbxb-text-sub-title"}>本保险由信美人寿提供</div>
                            <div className={"srbxb-jkgz-desc"}>
                                <div>长期有效：0-17岁孩子只需缴费一次，即可保障至25周岁</div>
                                <div>确诊即赔：购买90天后，初次确认白血病，可全额赔付</div>
                                <div>轻症也可保：轻症白血病，也可获保险赔付</div>
                            </div>
                        </div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·理赔无忧：</label>
                        <div className={"srbxb-service-text"}>为确诊家庭提供一站式无忧理赔服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·爱心救助：</label>
                        <div className={"srbxb-service-text"}>如投保家长确诊重疾，家庭困难的，还可额外申请20万救助金</div>
                    </div>

                    <div className={"srbxb-service-line-2"}>
                        为了保证你孩子的保障权益在理赔时不受影响，请确认你的孩子健康状况是否符合投保条件。
                    </div>

                    <div className={"srbxb-jkgz-box"}>
                        <div className={"srbxb-jkgz-content"}>
                            <div className={"srbxb-jkgz-title"}>
                                <span>1.</span>
                                <div className={"srbxb-jkgz-nr"}>
                                监护人是否为被保险人向任何保险公司提交过重大疾病、恶性肿瘤理赔申请?
                                </div>
                            </div>
                        </div>
                        <div className={"srbxb-jkgz-content"}>
                            <div className={"srbxb-jkgz-title"}>
                                <div className={"srbxb-jkgz-title"}>
                                    <span>2.</span>
                                    <div className={"srbxb-jkgz-nr"}>
                                        被保险人在近六个月内是否出现过以下症状?
                                    </div>
                                </div>
                                <div className={"srbxb-jkgz-desc"}>
                                    <div>不明原因的发热、头晕、
                                        腹痛、出血倾向(如牙龈、鼻腔出血或皮下有出血点、瘀斑)、紫癜、肝或脾脏肿大、淋巴结肿大、
                                        身体包块、结节或肿物、消瘦、血液细胞学检查提示血细胞异常增高或过低。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"srbxb-jkgz-content"}>
                            <div className={"srbxb-jkgz-title"}><span>3.</span>
                                <div className={"srbxb-jkgz-nr"}>
                                    被保险人是否曾被医生诊断、
                                    或被怀疑罹患包括白血病在内的造血系统疾病?是否被医生建议需进行骨髓活组织检查或/和进一步血液细胞学检查、
                                    遗传学检查?
                                </div>
                            </div>
                        </div>
                        <div className={"srbxb-jkgz-content"}>
                            <div className={"srbxb-jkgz-title"}>
                                <span>4. </span>
                                <div className={"srbxb-jkgz-nr"}>
                                    被保险人目前是否患有或者曾经患有以下疾病?
                                </div>
                            </div>
                            <div className={"srbxb-jkgz-desc"}>
                                <div>(1)聋哑、失明、烧烫伤、肢体残缺、畸形或者运动障碍;</div>
                                <div>(2)先天性心脏病、心脑血管疾病、川崎病、神经系统及精神疾病(脑炎、脑膜炎、严重脑损伤、智障、癫痫、
                                多发性硬化、重症肌无力、进行性脊肌萎缩症、抑郁症、孤独症);</div>
                                <div>(3)恶性肿瘤、颅内肿瘤、糖尿病、肝炎、肝硬化、肾病、白血病、再生障碍性贫血、
                                重度贫血、骨髓增生异常、艾滋病。</div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        }
        if (this.state.activeService === 3) {
            serviceStyleLi3 = "active-li-3";
            serviceContent = (
                <div className={"srbxb-yiliao-box"}>
                    <div className={"srbxb-service-line"}>
                        <label>·名院挂号：</label>
                        <div className={"srbxb-service-text"}>为患者提供知名三甲医院挂号服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·专家会诊：</label>
                        <div className={"srbxb-service-text"}>若病情复杂，安排专家会诊服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·医院推荐：</label>
                        <div className={"srbxb-service-text"}>知名三甲肿瘤医院的推荐及建议服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·就医评估：</label>
                        <div className={"srbxb-service-text"}>安排血液科医学专家为患者评估治疗方案</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·住院安排：</label>
                        <div className={"srbxb-service-text"}>确诊白血病后，安排孩子快速住院</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·护理服务：</label>
                        <div className={"srbxb-service-text"}>孩子治疗期间提供1对1护理师照顾</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·住院垫付：</label>
                        <div className={"srbxb-service-text"}>为经济困难家庭提供住院押金垫付服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·医院转诊：</label>
                        <div className={"srbxb-service-text"}>根据病情需要，提供知名三甲肿瘤医院转诊服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·空中救援：</label>
                        <div className={"srbxb-service-text"}>为情况危急的患者提供直升机紧急救援服务</div>
                    </div>

                    <div className={"srbxb-service-line"}>
                        <label>·骨髓配型：</label>
                        <div className={"srbxb-service-text"}>如需骨髓移植，联合中华骨髓库配型</div>
                    </div>
                </div>
            );
        }

        const visible = this.state.visible;
        let callBoxDiv = "";
        if(visible){
            callBoxDiv = (
                <div className={"srbxb-product-detail-call-box"}>
                    <div className={"zheBg"} onClick={()=>this.closeCallBox()}/>
                    <div className={"srbxb-call-box"}>
                        <div className={"common-plan-title srbxb-common-plan-title"}>
                            <span>客服电话号：</span><a onClick={()=>this.toCustomer()} className={"srbxb-customer-1"}
                                     href={"tel:"+this.state.phone}><label>{this.state.phone}</label></a>
                        </div>
                        <div className={"common-plan-title srbxb-common-plan-title"}>
                            <span>客服手机号：</span><a onClick={()=>this.toCustomer()} className={"srbxb-customer-1"}
                                     href={"tel:"+this.state.tel}><label>{this.state.tel}</label></a>
                        </div>
                        <div className={"common-plan-title srbxb-common-plan-title"}>
                            <span>客服微信号：</span><label className={"customer-label"} onClick={()=>this.copayValue()}>{this.state.wx}</label>
                        </div>

                        <div className={"common-plan-title srbxb-common-plan-title"} id={"online-call"}>
                            <span>在线客服：</span><label className={"customer-label"} onClick={()=>this.openOnlineService()}>在线客服</label>
                        </div>
                    </div>
                </div>
            );
        }
        let onlineServiceFrame = "";
        if (this.state.onlineService) {
            onlineServiceFrame = (
                <iframe className={"iframe-box"} src={"https://baozhunniu.udesk.cn/im_client/?web_plugin_id=84581"}/>
            );
        }
        return (
            <div>
                <div>
                    <div className={"preload-plan-50"}/>
                    <div className={"preload-plan-30"}/>
                </div>
                <div className={"page srbxb-content-box"}>
                    <img src={require('../../../assets/images/shaoerbaixiebing/product_detail_1.png')}
                        className={"srbxb-detail-1-img"} alt={title}/>
                    <div className={"srbxb-service-box"} id={"srbxb-service-box"}>
                        <div className={"srbxb-service-box-1"}>
                            <div className={serviceStyleLi1}
                                 onClick={()=>this.switchService(1)}>
                                无忧就医服务
                            </div>
                            <div className={serviceStyleLi2}
                                 onClick={()=>this.switchService(2)}>无忧保障服务</div>
                            <div className={serviceStyleLi3}
                                 onClick={()=>this.switchService(3)}>无忧治疗服务</div>
                        </div>
                        {serviceContent}

                    </div>
                    <div className={"srbxb-plan-descript-box"}>
                        <img src={require('../../../assets/images/shaoerbaixiebing/plan_descript.png')}
                             className={"srbxb-plan-descript-img"} alt={title}/>
                    </div>

                    <div className={"srbxb-plan-box"}>
                        <div style={plan50Style}
                             onClick={()=>this.switchAmount(50)}/>
                        <div style={plan30Style}
                             onClick={()=>this.switchAmount(30)}/>
                    </div>

                    <div className={"srbxb-tip"}>
                        {tip}
                        <img src={require('../../../assets/images/shaoerbaixiebing/caidai.png')}
                             className={"srbxb-caidai-img"} alt={title}/>
                    </div>

                    <img src={require('../../../assets/images/shaoerbaixiebing/woniu.png')}
                         className={"srbxb-woniu-img"} alt={title}/>

                </div>

                {callBoxDiv}

                <div className={"srbxb-to-proposal-box"}>
                    <div className={"srbxb-agree-box"}>

                        <div className={"srbxbagree-radio"}>
                            <div>
                                <input type={"checkbox"} id={"agree"}
                                       onChange={()=>this.agreeChange()}
                                       className={"srbxb-checkbox"}/>
                            </div>
                            <div>
                                <label htmlFor={"agree"} style={{paddingLeft:3}}>我已确认子女不存在</label>
                                <label className={"srbxb-notify"} onClick={()=>this.scrollToAnchor()}>《健康告知》</label>
                                <label htmlFor={"agree"}>中的情形。</label>
                            </div>
                        </div>
                        <div className={"srbxb-problem"} onClick={()=>this.toProblem()}>
                            常见问题
                        </div>
                    </div>
                    <div className={"srbxb-detail-button-box"}>
                        <div onClick={()=>this.openCallBox()} className={"srbxb-customer"}><label>客服</label></div>

                        <div className={"srbxb-toProposal"} onClick={()=>this.toDataInput()}>开启关爱保障</div>
                    </div>
                </div>

                <Modal
                    visible={this.state.onlineService}
                    transparent
                    maskClosable={true}
                    style={{width:"90%"}}
                    onClose={()=>this.closeOnlineService()}
                    footer={[{ text: '关闭', onPress: () => this.closeOnlineService()}]}
                    wrapProps={{ onTouchStart:(e)=> this.onWrapTouchStart(e)}}
                >
                    <div style={{ height: 480, overflow: 'scroll' }}>
                        {onlineServiceFrame}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state
});

export default connect(mapStateToProps,Action)(ProductDetail);
