import React,{ Component } from 'react';
import  './ProductDetail.less';
import {rootPath} from "@/service/xhr/config";
import Piwik from '@/util/Piwik';
import {title} from "@/constant/index";
import queryString from 'query-string';
import Util from '@/util/Util';
import {Checkbox, Picker,List} from 'antd-mobile';
const CheckboxItem = Checkbox.CheckboxItem;
import Share from '@/util/Share';
import * as Action from "@/store/token-action";
import { connect } from "react-redux";

class ProductDetail extends Component{
    constructor(props) {
        super(props);
        this.state={
            agree:false,
            phone:"4000-518-666",
            activeService:1,
            showProductPlan:false,
            currentPrice:"128",
            priceLabel:"起",
            ages:[
                {
                    "value": "1",
                    "label": "30天-3周岁"
                },
                {
                    "value": "2",
                    "label": "4周岁-17周岁"
                }
            ],
            products: {
                "P00001652001":{
                    "1": {
                        "text":"30天-3周岁",
                        "productPlanCode": "A0055_01",
                        "price": 128
                    },
                    "2": {
                        text:"4周岁-17周岁",
                        "productPlanCode": "A0055_02",
                        "price": 148
                    }
                },
                "P00001652002":{
                    "1": {
                        "text":"30天-3周岁",
                        "productPlanCode": "A0055_03",
                        "price": 198
                    },
                    "2": {
                        "text":"4周岁-17周岁",
                        "productPlanCode": "A0055_04",
                        "price": 218
                    }
                }
            },
            productCode:"P00001652001",
            channelNo:"",
            age:["1"],
            currentAgeValue: "1",
            defaultAge:["1"],
            defaultCurrentAgeValue: "1",
            defaultCurrentPrice:"128",
            defaultProductCode:"P00001652001"
        }
    }

    componentWillMount() {
        let urlParams = queryString.parse(this.props.location.search);
        let channelNo = "";
        if (urlParams!==undefined) {
            channelNo = urlParams.channelNo;
        }
        if(channelNo && channelNo !=="undefined"){
            this.props.setChannelNo({value:channelNo});
        }
        Share.initSrywWxShare(channelNo);
    }


    switchService(num){
        this.setState(state=>({
            activeService:num
        }));
    }

    switchProductPlan(value){
        const that = this;
        this.setState(state => ({
            productCode: value
        }),function () {
            that.getPrice()
        });
    }

    ageChange(v){
        const currentAgeValue = v['0'];
        const that = this;
        this.setState(state => ({
            age: v,
            currentAgeValue:currentAgeValue
        }),function () {
            that.getPrice();
        });
    }

    getPrice(){
        const price = this.state.products[this.state.productCode][this.state.currentAgeValue].price;
        this.setState(state => ({
            currentPrice: price
        }));
    }


    closeProductPlan(){
        const that = this;
        this.setState(state => ({
            showProductPlan:false,
            currentPrice: that.state.defaultCurrentPrice,
            age:that.state.defaultAge,
            currentAgeValue: that.state.defaultCurrentAgeValue,
            productCode: that.state.defaultProductCode,
            priceLabel: "起"
        }));
    }

    toDataInput(){

        if (!this.state.showProductPlan) {
            this.setState(state => ({
                showProductPlan:true,
                priceLabel:""
            }));
            return;
        }
        const productCode = this.state.productCode;
        this.props.history.replace({pathname:'/srywInput',state:{planCode:productCode}});
    }



    render(){
        let serviceStyleLi1 = "sryw-service-li";
        let serviceStyleLi2 = "sryw-service-li";
        let serviceContent = "";
        if (this.state.activeService === 1) {
            serviceStyleLi1 = "sryw-active-li-1";
            serviceContent = (
                <div className={"sryw-yiliao-box"}>
                    <div className={"sryw-service-line"}>
                        <label>·就医引导：</label>
                        <div className={"sryw-service-text"}>为患者家庭提供确诊后的一站式就医引导服务</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·慰问关怀：</label>
                        <div className={"sryw-service-text"}>为患者家庭提供身心康复指导及心灵疏导</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·营养搭配：</label>
                        <div className={"sryw-service-text"}>根据病人状况，提供营养搭配的指引服务</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·康复跟踪：</label>
                        <div className={"sryw-service-text"}>治疗结束前后的2年内提供康复、健康医疗咨询服务</div>
                    </div>
                </div>
            );
        }
        if (this.state.activeService === 2) {
            serviceStyleLi2 = "sryw-active-li-3";
            serviceContent = (
                <div className={"sryw-yiliao-box"}>
                    <div className={"sryw-service-line"}>
                        <label>·名院挂号：</label>
                        <div className={"sryw-service-text"}>为患者提供知名三甲医院挂号服务（1次）</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·专家会诊：</label>
                        <div className={"sryw-service-text"}>若病情复杂，安排专家级会诊服务（1次）</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·医院推荐：</label>
                        <div className={"sryw-service-text"}>知名三甲医院的推荐及建议服务</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·就医评估：</label>
                        <div className={"sryw-service-text"}>为患者提供治疗方案的评估及咨询建议</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·住院安排：</label>
                        <div className={"sryw-service-text"}>为确诊人员安排快速住院安排服务</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·护理服务：</label>
                        <div className={"sryw-service-text"}>特殊情况患者，治疗期间提供1对1护理师照顾</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·住院垫付：</label>
                        <div className={"sryw-service-text"}>为经济困难家庭提供住院押金垫付服务</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·医院转诊：</label>
                        <div className={"sryw-service-text"}>知名三甲医院的转诊就医服务	</div>
                    </div>

                    <div className={"sryw-service-line"}>
                        <label>·空中救援：</label>
                        <div className={"sryw-service-text"}>为情况危急的患者提供直升机紧急救援服务</div>
                    </div>
                </div>
            );
        }
        const productCode = this.state.productCode;
        let mark = (
            <img src={require('../../../assets/images/common/input_mark.png')}
                 className={"mark"} alt={title}/>
        );
        let yesMark = "";
        let noMark = "";
        let productActiveP00001652001="";
        let productActiveP00001652002="";
        let joinSocialNoActive = "";
        if (productCode === "P00001652001") {
            yesMark = mark;
            productActiveP00001652001 = "common-plan-button-active";
        }else{
            noMark = mark;
            productActiveP00001652002 = "common-plan-button-active";
        }
        let clauseMarginBottom = "sryw-margin-bottom";
        const showProductPlan = this.state.showProductPlan;
        let showProductPlanDiv = "";
        if (showProductPlan) {
            clauseMarginBottom = "";
            showProductPlanDiv = (
                <div className={"product-plan-box sryw-margin-bottom"} id={"product-plan-box"}>
                    <div className={"zheBg"} onClick={()=>this.closeProductPlan()}/>
                    <div className={"common-product-box"}>
                        <div className={"common-plan-title"}>
                            保障方案
                        </div>
                        <div className='plan-box'>
                            <div className={"common-plan-button " + productActiveP00001652001}
                                 onClick={() => this.switchProductPlan("P00001652001")}>
                                基础款
                                {yesMark}
                            </div>
                            <div className={"common-plan-button "+productActiveP00001652002 }
                                 onClick={() => this.switchProductPlan("P00001652002")}>
                                升级款
                                {noMark}
                            </div>
                        </div>
                        <div className={"common-plan-title"}>
                            年龄
                            <div className={"plan-box-2"}>
                                <Picker data={this.state.ages}
                                        cols={1}
                                        value={this.state.age}
                                        onOk={(e) => this.ageChange(e)}
                                        className="forss">
                                    <List.Item arrow="horizontal">请选择</List.Item>
                                </Picker>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return(

            <div>
                <div className={"page sryw-content-box"}>
                    <img src={require('../../../assets/images/shaoeryiwai/product_detail_1.png')}
                         className={"sryw-detail-1-img"} alt={title}/>
                    <div className={"sryw-service-box"} id={"sryw-service-box"}>
                        <div className={"sryw-service-box-1"}>
                            <div className={serviceStyleLi1}
                                 onClick={()=>this.switchService(1)}>
                                无忧就医服务
                            </div>
                            <div className={serviceStyleLi2}
                                 onClick={()=>this.switchService(2)}>无忧治疗服务</div>
                        </div>
                        {serviceContent}
                    </div>
                </div>
                {showProductPlanDiv}
                <div className={"sryw-to-proposal-box"}>
                    <div className={"sryw-detail-button-box"}>
                        <div className={"sryw-detail-price"}>
                            保费：
                            <label className={"sryw-detail-price-label-1"}>{this.state.currentPrice}</label>
                            <label>元{this.state.priceLabel}</label>
                        </div>

                        <div className={"sryw-toProposal"} onClick={()=>this.toDataInput()}>开启关爱保障</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state
});

export default connect(mapStateToProps,Action)(ProductDetail);
