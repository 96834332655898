import React,{ Component } from 'react';
import "./Offline.css";
import {title} from "@/constant/index";
import Share from '@/util/Share';

export default class Offline extends Component{
    constructor(props) {
        super(props);
        this.state = {
            tel:"4000-518-666"
        }
    }

    componentWillMount(){
        Share.initHiddenAllWx();
    }

    render(){
        return (
            <div>
                <div className={"srbxb-offline-box"}>
                    <div className={"srbxb-offline-img-box"}>
                        <img src={require('../../../assets/images/shaoerbaixiebing/offline-img.png')}
                             className={"srbxb-offline-img"} alt={title}/>
                        <div className={"srbxb-offline-text-box"}>
                            <div>尊敬的用户感谢您的关注与支持，</div>
                            <div>本次活动已经结束！</div>
                            <div>后续我们会持续为已投保用户提供服务和保障。</div>
                            <div>如有疑问请拨打
                                <a  className={"srbxb-offline-tel"} href={"tel:"+this.state.tel}><label>{this.state.tel}</label></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
