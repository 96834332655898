import {
    SET_ACCESS_TOKEN,
    GET_ACCESS_TOKEN,
    SET_CHANNEL_NO,
    GET_CHANNEL_NO,
    SET_SOURCE,
    GET_SOURCE,
    SET_WAIT_WX_RETURN,
    GET_WAIT_WX_RETURN,
    SET_USER_INFO,
    GET_USER_INFO
} from '@/constant/ActionTypes';

export const setAccessToken = (action) => {
    return {
        type: SET_ACCESS_TOKEN,
        value: action.value
    };
};

export const getAccessToken = () => {
    return {
        type: GET_ACCESS_TOKEN,
        value: action.value
    };
};


export const setChannelNo = (action) => {
    return {
        type: SET_CHANNEL_NO,
        value: action.value
    };
};

export const getChannelNo = () => {
    return {
        type: GET_CHANNEL_NO,
        value: action.value
    };
};


export const setSource = (action) => {
    return {
        type: SET_SOURCE,
        value: action.value
    };
};

export const getSource = () => {
    return {
        type: GET_SOURCE,
        value: action.value
    };
};


export const setWaitWxReturn = (action) => {
    return {
        type: SET_WAIT_WX_RETURN,
        value: action.value
    };
};

export const getWaitWxReturn = () => {
    return {
        type: GET_WAIT_WX_RETURN,
        value: action.value
    };
};

export const setUserInfo = (action) => {
    return {
        type: SET_USER_INFO,
        value: action.value
    };
};

export const getUserInfo = () => {
    return {
        type: GET_USER_INFO,
        value: action.value
    };
};
