/**
 * 埋点点击事件处理
 * @param v1 分类
 * @param v2 事件名称
 * @param v3 事件
 * @param v4 标识
 */
let push = function push(v1, v2, v3, v4) {

    const temps = [];
    temps.push("trackEvent");
    temps.push(v1);
    temps.push(v2);
    temps.push(v3);
    temps.push(v4);
    _paq.push(temps);
};

/**
 * 页面pv
 */
let pageView = function pageView() {
    _paq.push(['trackPageView']);
};


let exports = module.exports = {
    push: push,
    pageView: pageView
};
