import {
    SET_ACCESS_TOKEN,
    GET_ACCESS_TOKEN,
    SET_CHANNEL_NO,
    GET_CHANNEL_NO,
    SET_SOURCE,
    GET_SOURCE,
    SET_WAIT_WX_RETURN,
    GET_WAIT_WX_RETURN,
    SET_USER_INFO,
    GET_USER_INFO
} from '@/constant/ActionTypes';

const channelNo = sessionStorage.getItem(SET_CHANNEL_NO) ? sessionStorage.getItem(SET_CHANNEL_NO) : "";
const source = sessionStorage.getItem(SET_SOURCE) ? sessionStorage.getItem(SET_SOURCE) : "";
const waitWxReturn = sessionStorage.getItem(SET_WAIT_WX_RETURN) ? sessionStorage.getItem(SET_WAIT_WX_RETURN) : "false";
const userInfo = sessionStorage.getItem(SET_USER_INFO) ? sessionStorage.getItem(SET_USER_INFO) : "";
const initialState = {
    accessToken: "",
    channelNo: channelNo,
    source: source,
    waitWxReturn: waitWxReturn,
    userInfo: userInfo
};

export default (state, action) => {
    if(state==undefined){
        state = initialState;
    }
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            state["accessToken"]=action.value;
            return state;
        case GET_ACCESS_TOKEN:
            return state.accessToken;
        case SET_CHANNEL_NO:
            sessionStorage.setItem(SET_CHANNEL_NO, action.value);
            state["channelNo"]=action.value;
            return state;
        case GET_CHANNEL_NO:
            return state.channelNo;
        case SET_SOURCE:
            sessionStorage.setItem(SET_SOURCE, action.value);
            state["source"]=action.value;
            return state;
        case GET_SOURCE:
            return state.source;
        case SET_WAIT_WX_RETURN:
            sessionStorage.setItem(SET_WAIT_WX_RETURN, action.value);
            state["waitWxReturn"]=action.value;
            return state;
        case GET_WAIT_WX_RETURN:
            return state.waitWxReturn;
        case SET_USER_INFO:
            sessionStorage.setItem(SET_USER_INFO, action.value);
            state["userInfo"]=action.value;
            return state;
        case GET_USER_INFO:
            return state.userInfo;
        default:
            return state;
    }
};


