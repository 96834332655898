import React, {Component} from 'react';
import "./ProductDetail.css";
import {title} from "@/constant/index";
import Util from '@/util/Util';
import * as Action from "@/store/token-action";
import { connect } from "react-redux";
import queryString from "query-string";
import Share from '@/util/Share';
import {Picker, List, Modal} from "antd-mobile";
import {rootPath, fileSourcePath} from "@/service/xhr/config";
import clauseData from './ClauseData';
import Piwik from '@/util/Piwik';
import Login from '@/components/Login/Login';
import copy from 'copy-to-clipboard';
import {indexPath} from './config';

function closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

class ProductDetail extends Component{

    constructor(props) {
        super(props);
        this.state={
            title: "保准牛",
            agree:false,
            phone:"4000-518-666",
            mobile:"13102061402",
            wx:"fairyzoo",
            joinSocial:1,
            showProductPlan:false,
            showCustomerService:false,
            onlineService:false,
            currentPrice:"24",
            defaultPrice:"24",
            priceLabel:"起",
            openType: "",
            currentImages: [],
            clauseVisible:false,
            source:"",
            showLogin:false,
            ages:[
                {
                    "value": "1",
                    "label": "0-5周岁"
                },
                {
                    "value": "2",
                    "label": "6-10周岁"
                },
                {
                    "value": "3",
                    "label": "11-15周岁"
                },
                {
                    "value": "4",
                    "label": "16-20周岁"
                },
                {
                    "value": "5",
                    "label": "21-25周岁"
                },
                {
                    "value": "6",
                    "label": "26-30周岁"
                },
                {
                    "value": "7",
                    "label": "31-35周岁"
                },
                {
                    "value": "8",
                    "label": "36-40周岁"
                },
                {
                    "value": "9",
                    "label": "41-45周岁"
                },
                {
                    "value": "10",
                    "label": "46-50周岁"
                },
                {
                    "value": "11",
                    "label": "51-55周岁"
                },
                {
                    "value": "12",
                    "label": "56-60周岁"
                },
                {
                    "value": "13",
                    "label": "61-65周岁"
                }
            ],
            price:{
                "1":{
                    joinPrice: "62",
                    noJoinPrice: "146"
                },
                "2":{
                    joinPrice: "36",
                    noJoinPrice: "78"
                },
                "3":{
                    joinPrice: "26",
                    noJoinPrice: "54"
                },
                "4":{
                    joinPrice: "24",
                    noJoinPrice: "54"
                },
                "5":{
                    joinPrice: "27",
                    noJoinPrice: "73"
                },
                "6":{
                    joinPrice: "38",
                    noJoinPrice: "104"
                },
                "7":{
                    joinPrice: "51",
                    noJoinPrice: "137"
                },
                "8":{
                    joinPrice: "70",
                    noJoinPrice: "182"
                },
                "9":{
                    joinPrice: "93",
                    noJoinPrice: "236"
                },
                "10":{
                    joinPrice: "121",
                    noJoinPrice: "300"
                },
                "11":{
                    joinPrice: "163",
                    noJoinPrice: "391"
                },
                "12":{
                    joinPrice: "212",
                    noJoinPrice: "496"
                },
                "13":{
                    joinPrice: "289",
                    noJoinPrice: "668"
                },
            },
            age:["4"],
            currentAgeValue: "4",
            defaultAge:["4"],
            defaultCurrentAgeValue: "4"
        }
    }

    agreeChange() {
        const that = this;
        this.setState(state=>({
            agree: !that.state.agree
        }));
    }

    toClause(openType){
        this.setState(state=>({
            currentImages: clauseData[openType],
            openType: openType,
            clauseVisible:true
        }));

    }

    closeClause(){
        this.setState(state => ({
            clauseVisible: false
        }));
    }

    onWrapTouchStart(e){
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }

    ageChange(v){
        const currentAgeValue = v['0'];
        const that = this;
        this.setState(state => ({
            age: v,
            currentAgeValue:currentAgeValue
        }),function () {
            that.getPrice();
        });
    }
    componentDidMount() {
        document.title = this.state.title;
    }
    componentWillMount() {
        let urlParams = queryString.parse(this.props.location.search);
        let channelNo = "";
        let source = "";
        if (urlParams!==undefined) {
            channelNo = urlParams.channelNo;
            source = urlParams.source;
        }
        if(channelNo && channelNo !=="undefined"){
            this.props.setChannelNo({value:channelNo});
        }
        if (source && source !== undefined && source !== "undefined") {
            this.props.setSource({value:source});
            this.setState(state => ({
                source: source
            }));
        }
        Share.initYYebWxShare(channelNo);
        Piwik.pageView();
    }

    setShowLogin(value) {
        this.setState(state => ({
            showLogin: value
        }));
    }

    loginSuccess(){
        this.setShowLogin(false);
        this.setState(state => ({
            showProductPlan: true
        }));
    }

    toDataInput(){
        //app嵌入时需要判断登录
        if ("app" === this.state.source) {
            const userInfo = this.props.data.userInfo;
            //未登录，需要弹出登录框
            if ("" === userInfo || undefined === userInfo || "undefined" === userInfo) {
                this.setShowLogin(true);
                return;
            }else{
                this.setState(state => ({
                    showLogin: false
                }));
            }
        }

        if (!this.state.showProductPlan) {
            this.setState(state => ({
                showProductPlan:true,
                showCustomerService:false,
                priceLabel:""
            }));
            return;
        }

        if (!this.state.agree) {
            Util.showToast("请确认不存在《健康告知》中的情形");
            return;
        }
        const that = this;
        Util.lastPayTimeValidate( function () {
            const joinSocial = that.state.joinSocial;
            const source = that.state.source;
            that.props.history.replace({pathname: '/yyebInput', state: {joinSocial: joinSocial, source: source}});
        });

    }

    switchJoinSocial(value){
        const that = this;
        this.setState(state => ({
            joinSocial: value
        }),function () {
            that.getPrice()
        });
    }

    closeProductPlan(){
        const that = this;
        this.setState(state => ({
            showProductPlan:false,
            currentPrice: that.state.defaultPrice,
            age:that.state.defaultAge,
            currentAgeValue: that.state.defaultCurrentAgeValue,
            joinSocial:1,
            priceLabel: "起"
        }));
    }

    getPrice(){
        const joinSocial =  this.state.joinSocial;
        const currentAgeValue = this.state.currentAgeValue;
        let price = "";
        if (joinSocial === 1) {
            price = this.state.price[currentAgeValue].joinPrice;
        }else{
            price = this.state.price[currentAgeValue].noJoinPrice;
        }
        this.setState(state => ({
            currentPrice: price
        }));
    }

    closeCustomer(){
        this.setState(state => ({
            showCustomerService:false,
        }));
    }

    openCustomer() {
        const that = this;
        that.closeProductPlan();
        this.setState(state => ({
            showCustomerService: !that.state.showCustomerService,
        }));
    }

    copayValue(value){
        copy(value);
        Util.showToast("已复制成功");
    }

    openOnlineService(){
        const that = this;
        this.setState(state => ({
            onlineService: !that.state.onlineService
        }));
    }

    closeOnlineService(){
        const that = this;
        this.setState(state => ({
            onlineService: !that.state.onlineService
        }));
    }

    backToHome(){
        //app中回到app
        if ("app" === this.state.source) {
            if(Util.isIOS()){
                window.webkit.messageHandlers.nativeCloseWindow.postMessage("bzn-close");
            }else{
                window.android.finshActivity();
            }
        }else{
            //非app回到首页
            //Util.toHome();
            window.location.replace(indexPath);
        }
    }

    callPhone(tel){
        //沣石app中拨号
        if ("app" === this.state.source) {
            if(Util.isIOS()){
                Util.callPhoneFsIOS(tel);
            }else{
                Util.callPhoneFsAndroid(tel);
            }
        }else{
            Util.callPhone(tel);
        }
    }

    render(){
        const joinSocial = this.state.joinSocial;
        let mark = (
            <img src={require('../../../assets/images/common/input_mark.png')}
                 className={"mark"} alt={title}/>
        );
        let yesMark = "";
        let noMark = "";
        let joinSocialYesActive="";
        let joinSocialNoActive = "";
        if (joinSocial === 1) {
            yesMark = mark;
            joinSocialYesActive = "common-plan-button-active";
        }else{
            noMark = mark;
            joinSocialNoActive = "common-plan-button-active";
        }
        let clauseMarginBottom = "yyeb-margin-bottom";
        const showProductPlan = this.state.showProductPlan;
        let showProductPlanDiv = "";
        if (showProductPlan) {
            clauseMarginBottom = "";
            showProductPlanDiv = (
                <div className={"product-plan-box  yyeb-margin-bottom"} id={"product-plan-box"}>
                    <div className={"zheBg"} onClick={()=>this.closeProductPlan()}/>
                    <div className={"common-product-box"}>
                        <div className={"common-plan-title"}>
                            是否有社保
                        </div>
                        <div className='plan-box'>
                            <div className={"common-plan-button " + joinSocialYesActive}
                                 onClick={() => this.switchJoinSocial(1)}>
                                是
                                {yesMark}
                            </div>
                            <div className={"common-plan-button " + joinSocialNoActive}
                                 onClick={() => this.switchJoinSocial(0)}>
                                否
                                {noMark}
                            </div>
                        </div>
                        <div className={"common-plan-title"}>
                            年龄
                            <div className={"plan-box-2"}>
                                <Picker data={this.state.ages}
                                        cols={1}
                                        value={this.state.age}
                                        onOk={(e) => this.ageChange(e)}
                                        className="forss">
                                    <List.Item arrow="horizontal">请选择</List.Item>
                                </Picker>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const showCustomerService = this.state.showCustomerService;
        let showCustomerServiceDiv = "";
        if (showCustomerService) {
            showCustomerServiceDiv = (
                <div className={"yyeb-customer-content-box yyeb-margin-bottom"}>
                    <div className={"zheBg"} onClick={()=>this.closeCustomer()}/>
                    <div className={"yyeb-customer-boxes"}>
                        <div className={"yyeb-customer-line-box"}>
                            <div className={"yyeb-customer-line-title"}>
                                <img src={require("../../../assets/images/yueyueebao/mobile.png")}
                                 alt={title} className={"yyeb-customer-line-mobile"}/>
                                客服电话号
                            </div>
                            <div className={"yyeb-customer-line-content"}>
                                <span href={"tel:"+this.state.phone} className={"phone-style"}
                                      onClick={()=>this.callPhone(this.state.phone)}>{this.state.phone}</span>
                            </div>
                        </div>
                        <div className={"yyeb-customer-line-box"}>
                            <div className={"yyeb-customer-line-title"}>
                                <img src={require("../../../assets/images/yueyueebao/phone.png")}
                                     alt={title} className={"yyeb-customer-line-mobile"}/>
                                客服手机号
                            </div>
                            <div className={"yyeb-customer-line-content"}>
                                <span href={"tel:"+this.state.mobile} className={"phone-style"}
                                      onClick={()=>this.callPhone(this.state.mobile)}>{this.state.mobile}</span>
                            </div>
                        </div>
                        <div className={"yyeb-customer-line-box"}>
                            <div className={"yyeb-customer-line-title"}>
                                <img src={require("../../../assets/images/yueyueebao/weixin.png")}
                                     alt={title} className={"yyeb-customer-line-mobile"}/>
                                客服微信号
                            </div>
                            <div className={"yyeb-customer-line-content"} onClick={()=>this.copayValue(this.state.wx)}>
                                {this.state.wx}
                            </div>
                        </div>
                        <div className={"yyeb-customer-line-box"}>
                            <div className={"yyeb-customer-line-title"}>
                                <img src={require("../../../assets/images/yueyueebao/online-service.png")}
                                     alt={title} className={"yyeb-customer-line-mobile"}/>
                                在线客服
                            </div>
                            <div className={"yyeb-customer-line-content"} onClick={()=>this.openOnlineService()}>
                                <label className={"phone-style"}>在线客服</label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let srcPath = rootPath;

        if (this.state.openType === 'userProtocol' || this.state.openType === 'toubaoshenming') {
            srcPath = fileSourcePath;
        }
        const images = this.state.currentImages.map((item, index) => (
            <img key={index} src={srcPath + item} className={"clause-img"} alt={title}/>
        ));

        let loginDiv = "";
        if (this.state.showLogin) {
            loginDiv = (
                <Login showLogin={this.state.showLogin}
                       setShowLogin={this.setShowLogin.bind(this)}
                       loginSuccess={this.loginSuccess.bind(this)}
                />
            );
        }

        let onlineServiceFrame = "";
        if (this.state.onlineService) {
            onlineServiceFrame = (
                <iframe className={"iframe-box"} src={"https://baozhunniu.udesk.cn/im_client/?web_plugin_id=84581"}/>
            );
        }

        return (
            <div>
                <div className={"page yyeb-content-box"}>
                    <img src={require('../../../assets/images/yueyueebao/product_detail_1.png')}
                         className={"yyeb-detail-1-img"} alt={title}/>
                </div>

                {/*<div className={"yyeb-more-info-box"}>*/}
                {/*更多信息请阅读<label onClick={() => this.toClause("clauseData")}>《保险条款》</label>及*/}
                {/*<label onClick={() => this.toClause("noticeData")}>《投保须知》</label>；具体保障内容以保险条款为准。*/}

                {/*</div>*/}

                {/*<div className={"page yyeb-content-box"}>*/}
                {/*<img src={require('../../../assets/images/yueyueebao/product_detail_2.png')}*/}
                {/*className={"yyeb-detail-1-img"} alt={title}/>*/}
                {/*</div>*/}

                <div className={"service-360-box"}>
                    <div className={"service-box"}>
                        <div className={"service-1-title-img-box"}>
                            <img src={require('../../../assets/images/yueyueebao/01_icon.png')}
                                 className={"service-1-title-img"} alt={title}/>
                        </div>
                        <div className={"service-1-content-box"}>
                            <p className={"service-1-no-img-box"}>
                                <img src={require('../../../assets/images/yueyueebao/01.png')}
                                     hspace="5"
                                     vspace="5"
                                     className={"service-1-no-img"} alt={title}/>
                                您可致电保准健康客服电话<span href={"tel:" + this.state.phone} className={"a-style"}
                                                  onClick={()=>this.callPhone(this.state.phone)}>{this.state.phone}</span>
                                或登录“保准健康”小程序， 点击“我的”按钮，查询保单信息。
                            </p>
                        </div>
                    </div>

                    <div className={"service-box-2"}>
                        <div className={"service-2-content-box"}>
                            <p className={"service-2-no-img-box"}>
                                <img src={require('../../../assets/images/yueyueebao/02.png')}
                                     hspace="5"
                                     vspace="5"
                                     className={"service-2-no-img"} alt={title}/>
                                您可登录易安财产保险股份有限公司官方网站 <a href="javascript:void(0)"
                                                        className={"a-style"}>https://www.1an.com/</a>，
                                点击“保单服务-保单查询”进行电子保险合同有效性验证。
                            </p>
                        </div>
                        <div className={"service-2-title-img-box"}>
                            <img src={require('../../../assets/images/yueyueebao/02_icon.png')}
                                 className={"service-2-title-img"} alt={title}/>
                        </div>
                    </div>

                    <div className={"service-box"}>
                        <div className={"service-1-title-img-box"}>
                            <img src={require('../../../assets/images/yueyueebao/03_icon.png')}
                                 className={"service-3-title-img"} alt={title}/>
                        </div>
                        <div className={"service-1-content-box"}>
                            <p className={"service-1-no-img-box"}>
                                <img src={require('../../../assets/images/yueyueebao/03.png')}
                                     hspace="5"
                                     vspace="5"
                                     className={"service-3-no-img"} alt={title}/>
                                如孩子有就诊需求，您可拨打健康专线<span href={"tel:400-012-1212"} className={"a-style"}
                                                       onClick={()=>this.callPhone("400-012-1212")}>400-012-1212</span>（8:00-20:00），由客服人员为您提供服务。
                            </p>
                        </div>
                    </div>
                </div>

                <div className={"yyeb-line"}/>
                {loginDiv}
                <div>
                    <img src={require('../../../assets/images/yueyueebao/product_detail_3.png')}
                         className={"yyeb-detail-2-img"} alt={title}/>
                </div>

                <div className={"yyeb-line"}/>

                <div className={'common-clause-box ' + clauseMarginBottom}>
                    <div className={"common-clause"}
                         onClick={() => this.toClause("noticeData")}>
                        <label>投保须知</label>
                    </div>
                    <div className={"common-clause"}
                         onClick={() => this.toClause("clauseData")}>
                        <label>易安财险个人中高端医疗保险条款（A款）</label>
                    </div>
                    <div className={"common-clause"}
                         onClick={() => this.toClause("serviceProtocol")}>
                        <label> 易安财险附加挂号服务保险条款</label>
                    </div>
                    <div className={"common-clause"}
                         onClick={() => this.toClause("toubaoshenming")}>
                        <label>投保申明</label>
                    </div>
                    <div className={"common-clause"}
                         onClick={() => this.toClause("userProtocol")}>
                        <label>保准牛用户协议</label>
                    </div>
                </div>

                <div className={"yyeb-line"}/>

                {showCustomerServiceDiv}

                {showProductPlanDiv}

                <input type={"hidden"} id={"yyeb-line-box"}/>
                <div className={"yyeb-to-proposal-box"}>
                    <div className={"yyeb-agree-box"}>
                        <div className={"yyeb-agree-radio"}>
                            <div>
                                <input type={"checkbox"} id={"agree"}
                                       onChange={() => this.agreeChange()}
                                       className={"yyeb-checkbox"}/>
                            </div>
                            <div>
                                <label htmlFor={"agree"} style={{paddingLeft: 3}}>我已确认不存在</label>
                                <label className={"yyeb-notify"}
                                       onClick={() => this.toClause("healthNotice")}>《健康告知》</label>
                                <label htmlFor={"agree"}>中的情形。</label>
                                <span className={"yyeb-customer-box"} onClick={()=>this.openCustomer()}>
                                    客服
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={"yyeb-detail-button-box"}>
                        <div className={"yyeb-customer"} onClick={()=>this.backToHome()}>
                            <img src={require('../../../assets/images/common/to_home.png')}
                                 className={"yyeb-detail-kefu"} alt={title}/>
                        </div>
                        <div className={"yyeb-detail-price"}>首月<label className={"yyeb-detail-price-label-1"}>1</label>
                            <label>元</label>，<br/><label className={"yyeb-detail-price-label-1"}>2-12</label>月每月
                            <label className={"yyeb-detail-price-label-1"}>{this.state.currentPrice}</label>
                            <label>元{this.state.priceLabel}</label>
                        </div>
                        <div onClick={() => this.toDataInput()} className={"yyeb-toProposal"}>立即投保</div>
                    </div>
                </div>
                <Modal
                    visible={this.state.clauseVisible}
                    transparent
                    maskClosable={true}
                    onClose={() => this.closeClause()}
                    footer={[{text: '关闭', onPress: () => this.closeClause()}]}
                    wrapProps={{onTouchStart: (e) => this.onWrapTouchStart(e)}}
                >
                    <div style={{height: 480, overflow: 'scroll'}}>
                        {images}
                    </div>
                </Modal>
                <Modal
                    visible={this.state.onlineService}
                    transparent
                    maskClosable={true}
                    style={{width:"90%"}}
                    onClose={()=>this.closeOnlineService()}
                    footer={[{ text: '关闭', onPress: () => this.closeOnlineService()}]}
                    wrapProps={{ onTouchStart:(e)=> this.onWrapTouchStart(e)}}
                >
                    <div style={{ height: 480, overflow: 'scroll' }}>
                        {onlineServiceFrame}
                    </div>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    data: state
});

export default connect(mapStateToProps,Action)(ProductDetail);
