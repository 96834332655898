export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';

export const SET_CHANNEL_NO = 'SET_CHANNEL_NO';
export const GET_CHANNEL_NO = 'GET_CHANNEL_NO';


export const SET_SOURCE = 'SET_SOURCE';
export const GET_SOURCE = 'GET_SOURCE';

export const SET_WAIT_WX_RETURN = 'SET_WAIT_WX_RETURN';
export const GET_WAIT_WX_RETURN = 'GET_WAIT_WX_RETURN';


export const SET_USER_INFO = 'SET_USER_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';
