import 'bundle-loader'
import Bundle from '@/components/LazyLoad/Bundle.js';
import LazyLoad from '@/components/LazyLoad/LazyLoad';
import Index from 'bundle-loader?lazy&name=Index!@pages/Index/Index';
import Pay from 'bundle-loader?lazy&name=Pay!@pages/Pay/Pay';
import ProductStatus from 'bundle-loader?lazy&name=ProductStatus!@pages/Pay/ProductStatus';
import ShaoErBaiXieBingInput
    from 'bundle-loader?lazy&name=ShaoErBaiXieBingInput!@pages/Product/ShaoErBaiXieBing/DataInput';
import ShaoErBaiXieBingDetail
    from '@pages/Product/ShaoErBaiXieBing/ProductDetail';
import PDFPage
    from 'bundle-loader?lazy&name=PDFPage!@pages/Product/Common/PDFPage';

import BaiXieBingClause from 'bundle-loader?lazy&name=BaiXieBingClause!@pages/Product/ShaoErBaiXieBing/Clause';

import BaiXieBingQuestion
    from 'bundle-loader?lazy&name=BaiXieBingQuestion!@pages/Product/ShaoErBaiXieBing/Question';
import BaiXieBingOffline from '@pages/Product/ShaoErBaiXieBing/Offline';


import YueYueEBaoInput
    from 'bundle-loader?lazy&name=YueYueEBaoInput!@pages/Product/YueYueEBao/DataInput';
import YueYueEBaoDetail
    from '@pages/Product/YueYueEBao/ProductDetail';
import YueYueEBaoPay from 'bundle-loader?lazy&name=Pay!@pages/Product/YueYueEBao/Pay';
import YueYueEBaoProductStatus from 'bundle-loader?lazy&name=ProductStatus!@pages/Product/YueYueEBao/ProductStatus';

import ShaoErYiWaiInput
    from 'bundle-loader?lazy&name=ShaoErYiWaiInput!@pages/Product/ShaoErYiWai/DataInput';
import ShaoErYiWaiDetail
    from '@pages/Product/ShaoErYiWai/ProductDetail';
import ShaoErYiWaiPay from 'bundle-loader?lazy&name=ShaoErYiWaiPay!@pages/Product/ShaoErYiWai/Pay';
import ShaoErYiWaiProductStatus
    from 'bundle-loader?lazy&name=ShaoErYiWaiProductStatus!@pages/Product/ShaoErYiWai/ProductStatus';


const routers = [
    {
        path:'/',
        exact:true,
        component: LazyLoad(Index)
    },
    {
        path:'/index',
        exact:true,
        component: LazyLoad(Index)
    },
    {
        path:'/pay',
        exact:true,
        component: LazyLoad(Pay)
    },
    {
        path:'/productStatus',
        exact:true,
        component: LazyLoad(ProductStatus)
    },
    {
        path:'/srbxbInput',
        exact:true,
        component: LazyLoad(ShaoErBaiXieBingInput)
    },
    {
        path:'/srbxbDetail',
        exact:true,
        component: BaiXieBingOffline
    },
    {
        path:'/srbxClause',
        exact:true,
        component: LazyLoad(BaiXieBingClause)
    },
    {
        path:'/pdf',
        exact:true,
        component: LazyLoad(PDFPage)
    },
    {
        path:'/srbxQuestion',
        exact:true,
        component: LazyLoad(BaiXieBingQuestion)
    },
    {
        path:'/yyebInput',
        exact:true,
        component: LazyLoad(YueYueEBaoInput)
    },
    {
        path:'/yyebDetail',
        exact:true,
        component: YueYueEBaoDetail
    },
    {
        path:'/yyebPay',
        exact:true,
        component: LazyLoad(YueYueEBaoPay)
    },
    {
        path:'/yyebProductStatus',
        exact:true,
        component: LazyLoad(YueYueEBaoProductStatus)
    },
    {
        path:'/srywDetail',
        exact:true,
        component: ShaoErYiWaiDetail
    },
    {
        path:'/srywInput',
        exact:true,
        component: LazyLoad(ShaoErYiWaiInput)
    },
    {
        path:'/srywPay',
        exact:true,
        component: LazyLoad(ShaoErYiWaiPay)
    },
    {
        path:'/srywProductStatus',
        exact:true,
        component: LazyLoad(ShaoErYiWaiProductStatus)
    }
];

export default routers;
