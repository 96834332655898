export default{
    noticeData: [
        "/pdf/ebao/xuzhi/1.jpg",
        "/pdf/ebao/xuzhi/2.jpg",
        "/pdf/ebao/xuzhi/3.jpg"
    ],
    clauseData: [
        "/pdf/ebao/akuan/1.jpg",
        "/pdf/ebao/akuan/2.jpg",
        "/pdf/ebao/akuan/3.jpg",
        "/pdf/ebao/akuan/4.jpg",
        "/pdf/ebao/akuan/5.jpg",
        "/pdf/ebao/akuan/6.jpg",
        "/pdf/ebao/akuan/7.jpg",
        "/pdf/ebao/akuan/8.jpg",
        "/pdf/ebao/akuan/9.jpg",
        "/pdf/ebao/akuan/10.jpg",
        "/pdf/ebao/akuan/11.jpg",
        "/pdf/ebao/akuan/12.jpg",
        "/pdf/ebao/akuan/13.jpg",
        "/pdf/ebao/akuan/14.jpg",
        "/pdf/ebao/akuan/15.jpg",
        "/pdf/ebao/akuan/16.jpg",
        "/pdf/ebao/akuan/17.jpg",
        "/pdf/ebao/akuan/18.jpg",
        "/pdf/ebao/akuan/19.jpg",
        "/pdf/ebao/akuan/20.jpg",
        "/pdf/ebao/akuan/21.jpg",
        "/pdf/ebao/akuan/22.jpg",
        "/pdf/ebao/akuan/23.jpg",
        "/pdf/ebao/akuan/24.jpg",
        "/pdf/ebao/akuan/25.jpg",
        "/pdf/ebao/akuan/26.jpg",
        "/pdf/ebao/akuan/27.jpg",
        "/pdf/ebao/akuan/28.jpg",
        "/pdf/ebao/akuan/29.jpg",
        "/pdf/ebao/akuan/30.jpg",
        "/pdf/ebao/akuan/31.jpg",
        "/pdf/ebao/akuan/32.jpg",
        "/pdf/ebao/akuan/33.jpg"
    ],
    customerNotificationData:[
        "/pdf/ebao/customerNotification_1.jpg",
        "/pdf/ebao/customerNotification_2.jpg"
    ],
    proposalVoiceData:[
        "/pdf/ebao/ebao_statement.jpg"
    ],
    serviceProtocol:[
        "/pdf/ebao/fuwutiaokuan/1.jpg",
        "/pdf/ebao/fuwutiaokuan/2.jpg"
    ],
    userProtocol:[
        "/data/template/Documents/User_agreement_3in1/1_01.jpg",
        "/data/template/Documents/User_agreement_3in1/1_02.jpg",
        "/data/template/Documents/User_agreement_3in1/1_03.jpg",
        "/data/template/Documents/User_agreement_3in1/1_04.jpg",
        "/data/template/Documents/User_agreement_3in1/1_05.jpg",
        "/data/template/Documents/User_agreement_3in1/1_06.jpg"
    ],
    healthNotice:[
        "/pdf/ebao/jiankanggaozhi/1.jpg",
        "/pdf/ebao/jiankanggaozhi/2.jpg"
    ],
    toubaoshenming:[
        "/data/template/Documents/tbsm.jpg",
    ]
}
