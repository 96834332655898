import xhr from '@/service/xhr/index';
import {rootPath} from "@/service/xhr/config";

const hiddenMenu = [
    'menuItem:share:qq',
    'menuItem:share:weiboApp',
    'menuItem:favorite',
    'menuItem:share:facebook',
    'menuItem:share:QZone',
    'menuItem:editTag',
    'menuItem:delete',
    'menuItem:copyUrl',
    'menuItem:originPage',
    'menuItem:readMode',
    'menuItem:openWithQQBrowser',
    'menuItem:openWithSafari',
    'menuItem:share:email',
    'menuItem:share:brand'
];

const srbxbWxShareData = {

    shareTitle: "凯叔“少儿白血病关爱保障计划”服务上线啦！",
    shareDes: "关爱儿童健康，守护3亿儿童成长",
    shareImage: rootPath+"/img/shaoerbaixubing/h5_share.jpg",
};

const yyebWxShareData = {

    shareTitle: "首月仅需1元，开启600万健康生活",
    shareDes: "最高600万元医疗保障，免赔额更低，100种重疾0免赔",
    shareImage: rootPath+"/img/yueyueebao/share.jpg",
};


const srywShareData = {
    shareTitle: "凯叔“少儿关爱保障计划”上线啦！",
    shareDes: "关爱3亿儿童，守护健康成长",
    shareImage: rootPath+"/img/shaoeryiwai/share.jpg",
};

let initSrbxbWxShare = function initSrbxbWxShare(channelNo) {
    const shareUrl = decodeURI(`${window.location.protocol}//${window.location.host}/srbxbDetail?channelNo=`
        + channelNo);
    this.initWxShare(srbxbWxShareData, shareUrl);
};

let initYYebWxShare = function initYYebWxShare(channelNo) {
    const shareUrl = decodeURI(`${window.location.protocol}//${window.location.host}/yyebDetail?channelNo=`
        + channelNo);
    this.initWxShare(yyebWxShareData, shareUrl);
};

let initSrywWxShare = function initSrywWxShare(channelNo) {
    const shareUrl = decodeURI(`${window.location.protocol}//${window.location.host}/srywDetail?channelNo=`
        + channelNo);
    this.initWxShare(srywShareData, shareUrl);
};

let initHiddenAllWx = function initHiddenAllWx() {
    let param = {};
    param.url = window.document.location.href.split('#')[0];
    const that = this;
    const hiddenMenu2 = [
        'menuItem:share:qq',
        'menuItem:share:weiboApp',
        'menuItem:favorite',
        'menuItem:share:facebook',
        'menuItem:share:QZone',
        'menuItem:editTag',
        'menuItem:delete',
        'menuItem:copyUrl',
        'menuItem:originPage',
        'menuItem:readMode',
        'menuItem:openWithQQBrowser',
        'menuItem:openWithSafari',
        'menuItem:share:email',
        'menuItem:share:appMessage',
        'menuItem:share:timeline',
        'menuItem:share:brand'
    ];
    xhr.get('/openapi/wx/common/jsErvice', param).then(function (data) {
        if (data.code === "1") {
            try {
                wx.config({
                    debug: false,
                    appId: data.data.appId,
                    timestamp: data.data.timestamp,
                    nonceStr: data.data.nonceStr,
                    signature: data.data.signature,
                    jsApiList: ['hideMenuItems']
                });

                wx.ready(function () {
                    wx.checkJsApi({
                        // 分享到朋友圈等接口
                        jsApiList: [
                            'hideMenuItems'
                        ],
                        success: function () {
                            wx.hideMenuItems({
                                menuList: hiddenMenu2
                            });
                        }
                    });
                });
            } catch (e) {

            }
        }
    });
};

let initWxShare = function initWxShare(shareData,shareUrl) {

    const shareTitle = shareData.shareTitle;
    const shareDes = shareData.shareDes;
    const shareImage = shareData.shareImage;
    let param = {};
    param.url = window.document.location.href.split('#')[0];
    const that = this;
    xhr.get('/openapi/wx/common/jsErvice', param).then(function (data) {
        if (data.code === "1") {
            try {
                wx.config({
                    debug: false,
                    appId: data.data.appId,
                    timestamp: data.data.timestamp,
                    nonceStr: data.data.nonceStr,
                    signature: data.data.signature,
                    jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage','hideMenuItems']
                });

                wx.ready(function () {
                    wx.checkJsApi({
                        // 分享到朋友圈等接口
                        jsApiList: ['onMenuShareTimeline',
                            'onMenuShareAppMessage',
                            'hideMenuItems'
                        ],
                        success: function () {
                            wx.onMenuShareTimeline({
                                title: shareTitle,
                                link: shareUrl,
                                imgUrl: shareImage,
                                success: function () {

                                },
                                cancel: function () {

                                }
                            });
                            wx.onMenuShareAppMessage({
                                title: shareTitle,
                                desc: shareDes,
                                link: shareUrl,
                                imgUrl: shareImage,

                                success: function () {

                                },
                                cancel: function () {

                                }
                            });
                            wx.hideMenuItems({
                                menuList: hiddenMenu
                            });
                        }
                    });
                });
            } catch (e) {

            }
        }
    });
};

let exports = module.exports = {
    initSrbxbWxShare: initSrbxbWxShare,
    initYYebWxShare: initYYebWxShare,
    initSrywWxShare:initSrywWxShare,
    initWxShare: initWxShare,
    initHiddenAllWx:initHiddenAllWx
};
